import chatbot_payments from "./assets/img/digital_payments.png";
import chatbot_services from "./assets/img/seva_kendra_logo.png";
import chatbot_grievances from "./assets/img/grievances.png";
import chatbot_marketing from "./assets/img/marketing.png";
import chatbot_query_resolution from "./assets/img/query.png";
import chatbot_updates_notifications from "./assets/img/updates.png";
import chatbot_document_management from "./assets/img/document.png";

import urban_rural_bodies from "./assets/img/blockchain 1.png";
import zilla_parishad from "./assets/img/blockchain 1.png";
import district_collectorate from "./assets/img/blockchain 1.png";
import ministries from "./assets/img/blockchain 1.png";
import govt_companies_and_schemes from "./assets/img/blockchain 1.png";
import govt_colleges_health_centres from "./assets/img/blockchain 1.png";
//
import govt_institution from "./assets/img/govt_institution.png";
import bank_financial_institution from "./assets/img/bank_financial_institution.png";
import educational_institute from "./assets/img/educational_institute.png";
import police_department from "./assets/img/police_department.png";
import real_estate from "./assets/img/real_estate.png";
import cooperative from "./assets/img/cooperative.png";
import entertainment from "./assets/img/entertainment.png";
import transport from "./assets/img/transport.png";
import hospitality from "./assets/img/hospitality.png";
import fitness from "./assets/img/fitness.png";

// platforms
import whatsapp from "./assets/img/whatsapp.png";
import instagram from "./assets/img/instagram.png";
import telegram from "./assets/img/telegram.png";
import gmail from "./assets/img/gmail.png";
import facebook from "./assets/img/twitter.png";
import website from "./assets/img/website.png";
import linkedin from "./assets/img/linkedin.png";

//
import broadcast from "./assets/img/broadcast.png";
import chatbots from "./assets/img/chatbots.png";
import multiple_agents from "./assets/img/multiple_agents.png";
import low_code_automation from "./assets/img/low_code_automation.png";
import ono_to_one_communication from "./assets/img/ono_to_one_communication.png";
import easy_integration from "./assets/img/easy_integration.png";

export const chatbot_images = {
  chatbot_payments,
  chatbot_services,
  chatbot_grievances,
  chatbot_marketing,
  chatbot_query_resolution,
  chatbot_updates_notifications,
  chatbot_document_management,
};
export const industries_images = {
  urban_rural_bodies,
  zilla_parishad,
  district_collectorate,
  police_department,
  ministries,
  govt_companies_and_schemes,
  govt_colleges_health_centres,
  govt_institution,
  bank_financial_institution,
  educational_institute,
  real_estate,
  cooperative,
  entertainment,
  transport,
  hospitality,
  fitness,
};

export const platforms = {
  whatsapp,
  instagram,
  telegram,
  gmail,
  facebook,
  website,
  linkedin,
};

export const features_images= {
  broadcast,
  chatbots,
  multiple_agents,
  low_code_automation,
  ono_to_one_communication,
  easy_integration,
};
